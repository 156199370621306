import I18n from "i18n-js";

import translationEN from "./en.json";
import translationBG from "./bg.json";

I18n.defaultLocale = "bg";
I18n.locale = "bg";

I18n.translations = {
  bg: translationBG,
  en: translationEN,
};

export const translate = I18n.t.bind(I18n);

//I18n.init({escapeInterpolation: false });
//i18next.t('keyEscaped', { myVar: '<img />', interpolation: { escapeValue: false } })

export const setLocale = (locale: string) => (I18n.locale = locale);
export const getLocale = () => I18n.currentLocale();
