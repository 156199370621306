import React from "react";
import { translate } from "../../translations/localization";

import "./styles.css";

function ReservationBanner() {
  return (
    <div className="reservation-banner-container">
      <div
        className="reserve-button"
        onClick={() => window.open("https://clock.apoloniaresort.bg")}
      >
        {translate("tBookNow")}
      </div>
    </div>
  );
}

export default ReservationBanner;
