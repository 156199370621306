import React, { useEffect } from "react";
import { Header, Footer, FormContactUs, Map, ReservationBanner } from "../../components";
import { translate } from "../../translations/localization.js";
import "./styles.css";
import { connect } from "react-redux";
import { getData } from "../../actions";

const NewAboutUs = ({ getData, sections, language }) => {
  useEffect(() => {
    getData({ page: "aboutus" });
  }, []);

  return (
    <div className="wrap-all about-us">
      <Header fields={sections.filter((sect) => sect.for === "header")[0]} />

      <section
        className="section-header  overlay-gradient-4"
        style={{
          backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "landing")?.[0]?.fields?.backgroundImage?.[0]})`,
        }}
      >
        <div className="flex align-middle">
          <div className="container">
            <h1>{translate("tAboutUs")}</h1>
          </div>
        </div>
        <ReservationBanner />
      </section>

      <section className="section">
        <div className="row">
          <div
            className="col col-img"
            style={{
              backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.images?.[0]})`,
            }}
          >
            &nbsp;
          </div>
          <div className="col col-info">
            {language === "bg" ? (
              <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "section_1")?.[0]?.fields?.text }}></p>
            ) : (
              <p>{translate("aboutUsP1")}</p>
            )}
            {/*  */}
          </div>
        </div>
      </section>

      <section className="section">
        <div className="row">
          <div className="col col-info">
            {language === "bg" ? (
              <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "section_2")?.[0]?.fields?.text }}></p>
            ) : (
              <p>{translate("aboutUsP2")}</p>
            )}
          </div>
          <div
            className="col col-img"
            style={{
              backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "section_2")?.[0]?.fields?.images?.[0]})`,
            }}
          >
            &nbsp;
          </div>
        </div>
      </section>

      <section className="section">
        <div className="row">
          <div className="col col-map">
            <Map
              id="myMap"
              options={{
                center: { lat: 42.4250909, lng: 27.642771 },
                zoom: 13,
              }}
              onMapLoad={(map) => {}}
            />
          </div>
          <div className="col col-form-contact-us">
            <div className="form-contact-us-box">
              <h2 className="headline-up">{translate("aboutUsP3")}</h2>
              <FormContactUs />
            </div>
          </div>
        </div>
      </section>

      <Footer fields={sections?.filter((sect) => sect.for === "footer")[0]?.fields}  phones={sections.filter((sect) => sect.for === "header")[0]?.fields?.phone}/>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
    sections: state.data.sections,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getData: (payload) => dispatch(getData(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewAboutUs);
