import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import { translate } from "../../../translations/localization";
// import { translate } from "../../../translations/localization.js"

class GallerySlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      images: ["/imgs/apartment-double-1.jpg", "/imgs/apartment-double-2.jpg", ""],
      isOpenLightbox: false,
      current: "",
    };
  }

  getSliderSettings() {
    return {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0",
      slidesToShow: 2,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            centerMode: false,
            infinite: true,
            centerPadding: "0",
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            dots: false,
            arrows: true,
          },
        },
      ],
    };
  }

  handleClickImage = (e, image, index) => {
    e && e.preventDefault();

    this.setState({
      isOpenLightbox: true,
      current: image,
      photoIndex: index,
    });
  };

  handleCloseModal = (e) => {
    e && e.preventDefault();

    this.setState({
      current: false,
    });
  };

  render() {
    // const { language } = this.props;
    const settings = this.getSliderSettings();
    const { photoIndex, images, isOpenLightbox } = this.state;

    // var settingsGallerySlider = {
    //   className: "center",
    //   centerMode: true,
    //   infinite: true,
    //   centerPadding: "0",
    //   slidesToShow: 3,
    //   autoplay: true,
    //   autoplaySpeed: 5000,
    //   speed: 500,
    //   infinite: true,
    //   dots: false,
    //   arrows: true,
    //   responsive: [
    //     {
    //       breakpoint: 900,
    //       settings: {
    //         centerMode: false,
    //         infinite: true,
    //         centerPadding: "0",
    //         slidesToShow: 1,
    //         autoplay: true,
    //         autoplaySpeed: 5000,
    //         speed: 500,
    //         infinite: true,
    //         dots: false,
    //         arrows: true,
    //       }
    //     }
    //   ]
    // };

    return (
      <div className="slider-gallery">
        <h3 className="headline-up">{translate("tGallery")}</h3>

        <Slider {...settings}>
          {/* {...settingsGallerySlider} */}
          {this.props.images && this.props.images.map((image, index) => (
              <div>
                <div className="slide">
                  <img alt={image} src={image} onClick={(e) => this.handleClickImage(e, image, index)} />
                </div>
              </div>
            ))}
        </Slider>

        {isOpenLightbox && (
          <Lightbox
            // mainSrc={current}
            // onCloseRequest={this.handleCloseModal}

            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpenLightbox: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(GallerySlider);
