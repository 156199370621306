export const DataTypes = {
  GET_DATA: "data/GET_DATA",
  GET_DATA_SUCCESS: "data/GET_DATA_SUCCESS",
};

export const getData = (payload) => ({
  type: DataTypes.GET_DATA,
  payload,
});

export const getDataSuccess = (payload) => ({
  type: DataTypes.GET_DATA_SUCCESS,
  payload,
});
