import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import reducers from "../reducers";

import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "../epics";
const createHistory = require("history").createBrowserHistory;

const epicMiddleware = createEpicMiddleware();
const history = createHistory();

const middleware = [];
middleware.push(routerMiddleware(history));
middleware.push(epicMiddleware);


const configureStore = () => {
  const store = createStore(reducers, applyMiddleware(...middleware));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  epicMiddleware.run(rootEpic);
  return store;
};

export { configureStore, history };
