import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DatePickerStartDate extends Component {
  render(){
    return (

      <div>

          <div
            className="datepicker-input"
            onClick={this.props.onClick}>
            {this.props.value}
          </div>


      </div>
    )
  }
}

DatePickerStartDate.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

export default DatePickerStartDate;

