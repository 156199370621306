import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Header, Footer, ReservationBanner } from "../../../components";
import { translate } from "../../../translations/localization.js";
import { getData } from "../../../actions";
import { useEffect } from "react";

const NewAccomodation = ({ sections, getData, language }) => {
  useEffect(() => {
    getData({ page: "accomodation" });
  }, []);
  return (
    <div className="wrap-all accommodation">
      <Header fields={sections.filter((sect) => sect.for === "header")[0]} />
      <section
        className="section-header overlay-gradient-4"
        style={{
          backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "landing")?.[0]?.fields?.backgroundImage?.[0]})`,
        }}
      >
        <div className="flex align-bottom">
          <div className="container">
            <h1>{translate("roomTypesMainTitle1")}</h1>
            <div className="main-info">
              {language === "bg" ? (
                <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "landing")?.[0]?.fields?.text }}></p>
              ) : (
                <>
                  <p>{translate("roomTypesMainTitle2")}</p>
                  <p>{translate("roomTypesMainTitle3")}</p>
                </>
              )}
            </div>
          </div>
        </div>
        <ReservationBanner />
      </section>

      <section
        className="section section-roomtypes overlay-gradient-3"
        style={{
          backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "studio")?.[0]?.fields?.backgroundImage?.[0]})`,
        }}
      >
        <div className="align-middle flex">
          <div className="container">
            <div className="row cols-to-right">
              <div className="col-auto">
                <h2 className="headline-up">{translate("tStudio")}</h2>
                {language === "bg" ? (
                  <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "studio")?.[0]?.fields?.text }}></p>
                ) : (
                  <>
                    <p>
                      • {translate("squaring")} – 36 {translate("squaringUnit")}
                    </p>
                    <p>
                      • {translate("maximumAccommodation")} – {translate("maximumAccommotaion-1")}
                    </p>
                    <p>• {translate("doubleBed")}</p>
                    <p>• {translate("kitchenBox")}</p>
                    <p>• {translate("bathroom")}</p>
                    <p>• {translate("air-conditioning")}</p>
                    <p>• {translate("LCD")}</p>
                    <p>• {translate("refridgerator")}</p>
                    <p>• {translate("internet")}</p>
                    <p>• {translate("blowdryer")}</p>
                    <p>• {translate("teaHeater")}</p>
                    <p>• {translate("safe")} </p>
                    <p>• {translate("garden")}</p>{" "}
                  </>
                )}
                <p id="margin">
                  <Link to={`/${language}/room-types/studio`} className="link-more">
                    {translate("tViewMore")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section section-roomtypes overlay-gradient-1"
        style={{
          backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "double-room")?.[0]?.fields?.images?.[0]})`,
        }}
      >
        <div className="align-middle flex">
          <div className="container">
            <div className="row">
              <div className="col-auto">
                <h2 className="headline-up">{translate("tDoubleRoom")}</h2>
                {language === "bg" ? (
                  <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "double-room")?.[0]?.fields?.text }}></p>
                ) : (
                  <>
                    <p>
                      • {translate("squaring")} – 42 {translate("squaringUnit")}
                    </p>
                    <p>
                      • {translate("maximumAccommodation")} – {translate("maximumAccommodation-2")}{" "}
                    </p>
                    <p>• {translate("doubleBed")}</p>
                    <p>• {translate("couch")}</p>
                    <p>• {translate("bathroom")}</p>
                    <p>• {translate("air-conditioning")}</p>
                    <p>• {translate("LCD")}</p>
                    <p>• {translate("refridgerator")}</p>
                    <p>• {translate("internet")}</p>
                    <p>• {translate("blowdryer")}</p>
                    <p>• {translate("teaHeater")}</p>
                    <p>• {translate("safe")} </p>
                    <p>• {translate("garden")}</p>
                  </>
                )}
                <p id="margin">
                  <Link to={`/${language}/room-types/double-room`} className="link-more">
                    {translate("tViewMore")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section section-roomtypes overlay-gradient-1"
        style={{
          backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "apartment-single")?.[0]?.fields?.backgroundImage?.[0]})`,
        }}
      >
        <div className="align-middle flex">
          <div className="container">
            <div className="row cols-to-right">
              <div className="row">
                <div className="col-auto">
                  <h2 className="headline-up">
                    {translate("tApartment")}
                    <br />
                    {translate("tWithOneBedroom")}
                  </h2>
                  {language === "bg" ? (
                    <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "apartment-single")?.[0]?.fields?.text }}></p>
                  ) : (
                    <>
                      <p>
                        • {translate("squaring")}– 60-70 {translate("squaringUnit")}
                      </p>
                      <p>
                        • {translate("maximumAccommodation")} – {translate("maximumAccommodation-3")}{" "}
                      </p>
                      <p>• {translate("seperateSleepingRoom")}</p>
                      <p>• {translate("livingRoom")}</p>
                      <p>• {translate("bathroom")}</p>
                      <p>• {translate("air-conditioning")}</p>
                      <p>• {translate("LCD")}</p>
                      <p>• {translate("refridgerator")}</p>
                      <p>• {translate("internet")}</p>
                      <p>• {translate("blowdryer")}</p>
                      <p>• {translate("teaHeater")}</p>
                      <p>• {translate("safe")} </p>
                      <p>• {translate("garden")}</p>
                    </>
                  )}
                  <p id="margin">
                    <Link to={`/${language}/room-types/apartment`} className="link-more">
                      {translate("tViewMore")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section section-roomtypes overlay-gradient-3"
        style={{
          backgroundImage: `url(${sections && sections?.filter((sect) => sect.for === "apartment-double")?.[0]?.fields?.backgroundImage?.[0]})`,
        }}
      >
        <div className="align-middle flex">
          <div className="container">
            <div className="align-middle flex">
              <div className="col-auto">
                <h2 className="headline-up">
                  {translate("tApartment")}
                  <br />
                  {translate("tWithTwoBedrooms")}
                </h2>
                {language === "bg" ? (
                  <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "apartment-double")?.[0]?.fields?.text }}></p>
                ) : (
                  <>
                    <p>
                      • {translate("squaring")} – 80-100 {translate("squaringUnit")}
                    </p>
                    <p>
                      • {translate("maximumAccommodation")} – {translate("maximumAccommodation-4")}
                    </p>
                    <p>• {translate("bedroom")}</p>
                    <p>• {translate("livingRoom")}</p>
                    <p>• {translate("bathroom")}</p>
                    <p>• {translate("air-conditioning")}</p>
                    <p>• {translate("LCD")}</p>
                    <p>• {translate("refridgerator")}</p>
                    <p>• {translate("internet")}</p>
                    <p>• {translate("blowdryer")}</p>
                    <p>• {translate("teaHeater")}</p>
                    <p>• {translate("safe")} </p>
                    <p>• {translate("garden")}</p>
                  </>
                )}
                {/* <p>*{translate("note")}</p> */}
                <p id="margin">
                  <Link to={`/${language}/room-types/apartment-double`} className="link-more">
                    {translate("tViewMore")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer fields={sections?.filter((sect) => sect.for === "footer")[0]?.fields} phones={sections.filter((sect) => sect.for === "header")[0]?.fields?.phone}/>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
    sections: state.data.sections,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getData: (payload) => dispatch(getData(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewAccomodation);
