import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Slider from "react-slick";
import {translate} from "../../../translations/localization.js"

class SectionAccomodationDoubleRoom extends Component {

  render() {
    const {language} = this.props;

    const settingsAccomodationDoubleRoom = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    
    return (
    
        <div className="slider-full">
            <Slider {...settingsAccomodationDoubleRoom}>
                <div>
                    <div className='slide-inner align-middle flex overlay-gradient-1' style={{backgroundImage:'url("/imgs/apolonia-roomtypes-doubleroom.jpg")'}}>
                            <div className='container'>
                                <div className='row'>
                                <div className='col-auto'>
                                    <h2 className='headline-up'>Двойна стая</h2>
                                    <p>
                                    Стилната обстановка и невероятното разположение на ресторант Аполония го правят перфесктно място за Вашето сватбено тържество, романтични вечери, семейни срещи и фирмени коктейли!
                                    </p>
                                    <p><Link to={`/${language}/room-types/double-room`} className='link-more'>{translate("tViewMore")}</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='slide-inner align-middle flex overlay-gradient-1' style={{backgroundImage:'url("/imgs/apolonia-roomtypes-doubleroom-1.jpg")'}}>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-auto'>
                                        <h2 className='headline-up'>Двойна стая</h2>
                                        <p>
                                        Стилната обстановка и невероятното разположение на ресторант Аполония го правят перфесктно място за Вашето сватбено тържество, романтични вечери, семейни срещи и фирмени коктейли!
                                        </p>
                                        <p><Link to={`/${language}/room-types/double-room`} className='link-more'>{translate("tViewMore")}</Link></p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </Slider>
        </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language
  }
}

export default connect(mapStateToProps)(SectionAccomodationDoubleRoom); 
