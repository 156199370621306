import React, { Component } from "react";
import { connect } from "react-redux";

import { NewAccomodation } from "./Accommodation";

import { NewStudio, Studio } from "./Studio";
import { DoubleRoom, NewDoubleRoom } from "./DoubleRoom";
import { Apartment, NewApartment } from "./Apartment";
import { ApartmentDouble, NewApartmentDouble } from "./ApartmentDouble";

import { Route } from "react-router";
import { AnimatedSwitch } from "react-router-transition";

import "./styles.css";

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0.8,
  },
  // leave in a transparent, downscaled state
  atLeave: {},
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: 1,
  },
};

class RoomTypes extends Component {
  render() {
    return (
      <AnimatedSwitch
        atEnter={bounceTransition.atEnter}
        atLeave={bounceTransition.atLeave}
        atActive={bounceTransition.atActive}
        className="animated-switch"
      >
        <Route exact path={`${this.props.match.url}`} component={NewAccomodation} />
        <Route path={`${this.props.match.url}/studio`} component={NewStudio} />
        <Route path={`${this.props.match.url}/double-room`} component={NewDoubleRoom} />
        <Route path={`${this.props.match.url}/apartment-double`} component={NewApartmentDouble} />
        <Route path={`${this.props.match.url}/apartment`} component={NewApartment} />
      </AnimatedSwitch>
    );
  }
}

export default connect()(RoomTypes);
