import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.css";

class VideoBackground extends Component {
  render() {
    // const { language } = this.props;

    console.log(this.props.video && this.props.video.fields.video);
    return (
      <div className="video-background">
        <div className="video-foreground">
          <iframe
            title="full-screen-video"
            src={
              this.props.video
                ? this.props.video.fields.video
                : "https://www.youtube.com/embed/yT3wfdl-rJQ?&autoplay=1&mute=1&controls=0&showinfo=0&rel=0&loop=1&playlist=yT3wfdl-rJQ"
            }
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    );
  }
}

export default connect()(VideoBackground);
