import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Header, Footer, ReservationBanner } from "../../components";
import { getData } from "../../actions";
import { translate } from "../../translations/localization.js";
import "./styles.css";
import { useState } from "react";
import { lang } from "moment";

const NewSportAndSpa = ({ language, sections, getData }) => {
  useEffect(() => {
    getData({ page: "sport" });
  }, []);

  return (
    <div className="wrap-all sport-and-spa">
      <Header fields={sections.filter((sect) => sect.for === "header")[0]} />

      <section
        className="section-header overlay-gradient-5"
        style={{
          backgroundImage: 'url("/imgs/apolonia-hotel-sport-header.jpg")',
        }}
      >
        <div className="flex align-bottom">
          <div className="container">
            <h1>{translate("tSportAndSpa")}</h1>
            {language === "bg" ? (
              <p dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "landing")?.[0]?.fields?.text }}></p>
            ) : (
              <p>{translate("sportAndSpaP1")}</p>
            )}
          </div>
        </div>
        <ReservationBanner />
      </section>

      <section className="section-sport-and-spa">
        <div className="container">
          <div className="row">
            <div className="col col-auto">
              <div className="sport-box">
                <div className="img img-1">
                  <img
                    style={{ height: "400px" }}
                    src={sections?.filter((sect) => sect.for === "photos")?.[0]?.fields?.images?.filter((img) => img?.includes("fitness"))?.[0]}
                    alt=""
                  />
                </div>
                {language === "bg" ? (
                  <h3 dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "photos")?.[0]?.fields?.title1 }}></h3>
                ) : (
                  <h3>{translate("tOutdoorFitness")}</h3>
                )}
              </div>
            </div>
            <div className="col col-auto">
              <div className="sport-box">
                <div className="img">
                  <img
                    src={
                      sections?.filter((sect) => sect.for === "photos")?.[0]?.fields?.images?.filter((img) => img?.includes("volleyball"))?.[0]
                    }
                    alt=""
                  />
                </div>
                {language === "bg" ? (
                  <h3 dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "photos")?.[0]?.fields?.title2 }}></h3>
                ) : (
                  <h3>{translate("tBeachVolleyball")}</h3>
                )}
              </div>
            </div>
            {/*Тук детският кът е на мястото на тенис корта */}
            <div className="col col-auto">
              <div className="sport-box">
                <div className="img">
                  <img
                    src={sections?.filter((sect) => sect.for === "photos")?.[0]?.fields?.images?.filter((img) => img?.includes("kids"))?.[0]}
                    alt=""
                  />
                </div>
                {language === "bg" ? (
                  <h3 dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "photos")?.[0]?.fields?.title3 }}></h3>
                ) : (
                  <h3>{translate("tKidsSport")}</h3>
                )}
              </div>
            </div>
            <div className="col col-auto">
              <div className="sport-box">
                <div className="img">
                  <img
                    src={sections?.filter((sect) => sect.for === "photos")?.[0]?.fields?.images?.filter((img) => img?.includes("soccer"))?.[0]}
                    alt=""
                  />
                </div>
                {language === "bg" ? (
                  <h3 dangerouslySetInnerHTML={{ __html: sections?.filter((sect) => sect.for === "photos")?.[0]?.fields?.title4 }}></h3>
                ) : (
                  <h3>{translate("tBeachSoccer")}</h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
    sections: state.data.sections,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getData: (payload) => dispatch(getData(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewSportAndSpa);
