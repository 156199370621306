import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { translate } from "../../translations/localization.js";

class RoomTypesList extends Component {
  render() {
    const { language } = this.props;

    return (
      <div className="row row-room-types-list">
        <div className="col col-room-types-list-studio">
          <Link className="thumb" to={`/${language}/room-types/studio`}>
            <img
              style={{
                minHeight: "400px",
                maxHeight: "400px",
                objectFit: "cover",
              }}
              alt="Студио"
              src="/imgs/apolonia-studio-header.jpg"
            />
            <span className="title">{translate("roomListStudio")}</span>
          </Link>
          <Link className="link-more" to={`/${language}/room-types/studio`}>
            {translate("tViewMore")}
          </Link>
        </div>
        <div className="col col-room-types-list-double-room">
          <Link className="thumb" to={`/${language}/room-types/double-room`}>
            <img
              style={{
                minHeight: "400px",
                maxHeight: "400px",
                objectFit: "cover",
              }}
              alt="Двойна стая"
              src="/imgs/apolonia-hotel-roomtypes-list-studio.jpg"
            />
            <span className="title">{translate("roomListDoubleRoom")}</span>
          </Link>
          <Link
            className="link-more"
            to={`/${language}/room-types/double-room`}
          >
            {translate("tViewMore")}
          </Link>
        </div>
        <div className="col col-room-types-list-apartment-double">
          <Link
            className="thumb"
            to={`/${language}/room-types/apartment-double`}
          >
            <img
              alt="Апартамент с две спални"
              src="/imgs/apolonia-hotel-roomtypes-list-apartment-double.jpg"
              style={{
                minHeight: "400px",
                maxHeight: "400px",
                objectFit: "cover",
              }}
            />
            <span className="title">{translate("roomListTwoBedroom")}</span>
          </Link>
          <Link
            className="link-more"
            to={`/${language}/room-types/apartment-double`}
          >
            {translate("tViewMore")}
          </Link>
        </div>
        <div className="col col-room-types-list-apartment">
          <Link className="thumb" to={`/${language}/room-types/apartment`}>
            <img
              style={{
                minHeight: "400px",
                maxHeight: "400px",
                objectFit: "cover",
              }}
              alt="Апартамент с една спалня"
              src="/imgs/apolonia-hotel-apartment-header.jpg"
            />
            <span className="title">{translate("roomListApartment")}</span>
          </Link>
          <Link className="link-more" to={`/${language}/room-types/apartment`}>
            {translate("tViewMore")}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(RoomTypesList);
